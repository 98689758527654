import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_MUSIC,
    DELETE_MUSIC,
    MUSIC_FORM_TOOGLE_LOADING,
    SET_MUSIC_LIST,
    SHOW_NOTIFICATION,
    UPDATE_MUSIC,
} from "../constants";
import { formatMusics } from "./settingsActionsUtils";

/* MUSIC LIST */
export const fetchMusics = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.musics.list)
            .then((response) => {
                const musics = formatMusics(response.data);
                dispatch({
                    type: SET_MUSIC_LIST,
                    payload: keyBy(musics, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// MUSICS
export const createMusic = (newMusic) => {
    return async (dispatch) => {
        dispatch({ type: MUSIC_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.musics.create, newMusic)
            .then((response) => {
                const music = formatMusics(response.data);
                dispatch({ type: ADD_NEW_MUSIC, payload: music });
                dispatch({ type: MUSIC_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Música creada con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MUSIC_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateMusic = (updatedMusic) => {
    return async (dispatch) => {
        dispatch({ type: MUSIC_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.musics.edit}${updatedMusic && updatedMusic._id}`, updatedMusic)
            .then((response) => {
                const music = formatMusics(response.data);
                dispatch({ type: UPDATE_MUSIC, payload: music });
                dispatch({ type: MUSIC_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Música actualizada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MUSIC_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteMusics = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: MUSIC_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.musics.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_MUSIC, payload: Ids });
                dispatch({ type: MUSIC_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Música eliminada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: MUSIC_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
