import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_IDEA,
    DELETE_IDEA,
    IDEA_FORM_TOOGLE_LOADING,
    SET_IDEA_LIST,
    SHOW_NOTIFICATION,
    UPDATE_IDEA,
} from "../constants";
import { formatIdeas } from "./settingsActionsUtils";

/* IDEA LIST */
export const fetchIdeas = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.ideas.list)
            .then((response) => {
                const ideas = formatIdeas(response.data);
                dispatch({
                    type: SET_IDEA_LIST,
                    payload: keyBy(ideas, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// IDEAS
export const createIdea = (newIdea) => {
    return async (dispatch) => {
        dispatch({ type: IDEA_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.ideas.create, newIdea)
            .then((response) => {
                const idea = formatIdeas(response.data);
                dispatch({ type: ADD_NEW_IDEA, payload: idea });
                dispatch({ type: IDEA_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Idea creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: IDEA_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateIdea = (updatedIdea) => {
    return async (dispatch) => {
        dispatch({ type: IDEA_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.ideas.edit}${updatedIdea && updatedIdea._id}`, updatedIdea)
            .then((response) => {
                const idea = formatIdeas(response.data);
                dispatch({ type: UPDATE_IDEA, payload: idea });
                dispatch({ type: IDEA_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Idea actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: IDEA_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteIdeas = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: IDEA_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.ideas.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_IDEA, payload: Ids });
                dispatch({ type: IDEA_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Idea eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: IDEA_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
