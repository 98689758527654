import { handleActions, handleAction } from "redux-actions";
import {
  AUTH,
  SET_PROFILE,
  SET_CURRENT_ROUTE,
  LOGOUT,
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  SET_SIDE_MENU_STATE,
  OPEN_ALERT_FORM,
  CLOSE_ALERT_FORM,
  EDIT_SELECTED_ALERT,
  ALERT_FORM_TOOGLE_LOADING,
  SET_ALERT_LIST,
  ADD_NEW_ALERT,
  UPDATE_ALERT,
  DELETE_ALERT,
  THEME,
  ADD_NEW_GUEST,
  SET_GUEST_LIST,
  UPDATE_GUEST,
  DELETE_GUEST,
  OPEN_GUEST_FORM,
  CLOSE_GUEST_FORM,
  EDIT_SELECTED_GUEST,
  GUEST_FORM_TOOGLE_LOADING,
  ADD_NEW_CONFIGURATION,
  SET_CONFIGURATION_LIST,
  UPDATE_CONFIGURATION,
  DELETE_CONFIGURATION,
  OPEN_CONFIGURATION_FORM,
  CLOSE_CONFIGURATION_FORM,
  EDIT_SELECTED_CONFIGURATION,
  CONFIGURATION_FORM_TOOGLE_LOADING,
  ADD_NEW_IDEA,
  SET_IDEA_LIST,
  UPDATE_IDEA,
  DELETE_IDEA,
  OPEN_IDEA_FORM,
  CLOSE_IDEA_FORM,
  EDIT_SELECTED_IDEA,
  IDEA_FORM_TOOGLE_LOADING,
  ADD_NEW_MUSIC,
  SET_MUSIC_LIST,
  UPDATE_MUSIC,
  DELETE_MUSIC,
  OPEN_MUSIC_FORM,
  CLOSE_MUSIC_FORM,
  EDIT_SELECTED_MUSIC,
  MUSIC_FORM_TOOGLE_LOADING,
} from "./../../constants";
import { createCrudReducers } from "../../utils/crudGenerator";

/* BASIC */
export const authorization = handleActions(
  {
    [AUTH]: (state, action) => action.payload,
    [LOGOUT]: () => ({ auth: false }),
  },
  null
);

/* PROFILE */
export const profile = handleActions(
  {
    [SET_PROFILE]: (state, action) => action.payload,
  },
  null
);

/* APP */
export const currentRoute = handleAction(
  SET_CURRENT_ROUTE,
  (state, action) => action.payload,
  {}
);

export const notifications = handleActions(
  {
    [SHOW_NOTIFICATION]: (state, action) => action.payload,
    [CLOSE_NOTIFICATION]: (state, action) => ({
      show: false,
      status: null,
      message: null,
    }),
  },
  { show: false, status: null, message: null }
);

/* APP */
export const app = handleActions(
  {
    [SET_SIDE_MENU_STATE]: (state, action) => {
      return { ...state, isCollapsedSideMenu: action.payload };
    },
  },
  { isCollapsedSideMenu: true }
);

export const currentTheme = handleAction(
  THEME,
  (state, action) => true,
  true
);

export const alerts = handleActions(
  {
    [SET_ALERT_LIST]: (state, action) => action.payload,
    [ADD_NEW_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_ALERT]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_ALERT]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const alertsForm = handleActions(
  {
    [OPEN_ALERT_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_ALERT_FORM]: () => ({ show: false, data: null, loading: false }),
    [EDIT_SELECTED_ALERT]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [ALERT_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// GUESTS
export const guests = handleActions(
  {
    [SET_GUEST_LIST]: (state, action) => action.payload,
    [ADD_NEW_GUEST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_GUEST]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_GUEST]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const guestsForm = handleActions(
  {
    [OPEN_GUEST_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_GUEST_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_GUEST]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [GUEST_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// CONFIGURATIONS
export const configurations = handleActions(
  {
    [SET_CONFIGURATION_LIST]: (state, action) => action.payload,
    [ADD_NEW_CONFIGURATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_CONFIGURATION]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_CONFIGURATION]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const configurationsForm = handleActions(
  {
    [OPEN_CONFIGURATION_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_CONFIGURATION_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_CONFIGURATION]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [CONFIGURATION_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// IDEAS
export const ideas = handleActions(
  {
    [SET_IDEA_LIST]: (state, action) => action.payload,
    [ADD_NEW_IDEA]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_IDEA]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_IDEA]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const ideasForm = handleActions(
  {
    [OPEN_IDEA_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_IDEA_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_IDEA]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [IDEA_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);

// MUSICS
export const musics = handleActions(
  {
    [SET_MUSIC_LIST]: (state, action) => action.payload,
    [ADD_NEW_MUSIC]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [UPDATE_MUSIC]: (state, action) => {
      const newState = {
        ...state,
        [action.payload.value]: action.payload,
      };
      return newState;
    },
    [DELETE_MUSIC]: (state, action) => {
      action.payload.forEach((number) => {
        delete state[number];
      });
      return { ...state };
    },
  },
  null
);

export const musicsForm = handleActions(
  {
    [OPEN_MUSIC_FORM]: (state) => ({
      show: !state.show,
      data: null,
      loading: false,
    }),
    [CLOSE_MUSIC_FORM]: () => ({
      show: false,
      data: null,
      loading: false,
    }),
    [EDIT_SELECTED_MUSIC]: (s, action) => ({
      show: true,
      data: action.payload,
      loading: false,
    }),
    [MUSIC_FORM_TOOGLE_LOADING]: (state) => ({
      ...state,
      loading: !state.loading,
    }),
  },
  { show: false, data: null, loading: false }
);


const userReducers = createCrudReducers('USER');

export const users = userReducers.list;
export const usersForm = userReducers.form;
