import { createCrudActions } from '../utils/crudGenerator';
import moment from 'moment';

const formatUsers = (data) => {
  if (!data) {
    return [];
  }
  const formatItem = (item) => ({
    ...item,
    label: `${item.name} ${item.surname}`,
    value: item._id,
    last_login: item.last_login && moment(item.last_login),
    // Añade aquí cualquier otro campo que necesite formateo especial
  });

  if (Array.isArray(data)) {
    return data.map(formatItem);
  }
  return formatItem(data);
};

export const {
  fetch: fetchUsers,
  create: createUser,
  update: updateUser,
  delete: deleteUsers
} = createCrudActions('USER', "Usuario", formatUsers);