import { axios } from "../connection/ConnectionHandler";
import { API } from "../constants/api";
import { keyBy } from "lodash";
import {
    ADD_NEW_GUEST,
    DELETE_GUEST,
    GUEST_FORM_TOOGLE_LOADING,
    SET_GUEST_LIST,
    SHOW_NOTIFICATION,
    UPDATE_GUEST,
} from "../constants";
import { formatGuests } from "./settingsActionsUtils";

/* GUEST LIST */
export const fetchGuests = () => {
    return async (dispatch) => {
        const response = await axios
            .get(API.guests.list)
            .then((response) => {
                const guests = formatGuests(response.data);
                dispatch({
                    type: SET_GUEST_LIST,
                    payload: keyBy(guests, "_id"),
                });
                return response.status;
            })
            .catch((err) => err);
        return response;
    };
};

// GUESTS
export const createGuest = (newGuest) => {
    return async (dispatch) => {
        dispatch({ type: GUEST_FORM_TOOGLE_LOADING });
        const response = await axios
            .post(API.guests.create, newGuest)
            .then((response) => {
                const guest = formatGuests(response.data);
                dispatch({ type: ADD_NEW_GUEST, payload: guest });
                dispatch({ type: GUEST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "success",
                        message: "Invitado creado con éxito.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: GUEST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const updateGuest = (updatedGuest) => {
    return async (dispatch) => {
        dispatch({ type: GUEST_FORM_TOOGLE_LOADING });
        const response = await axios
            .put(`${API.guests.edit}${updatedGuest && updatedGuest._id}`, updatedGuest)
            .then((response) => {
                const guest = formatGuests(response.data);
                dispatch({ type: UPDATE_GUEST, payload: guest });
                dispatch({ type: GUEST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Invitado actualizado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: GUEST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};

export const deleteGuests = (Ids) => {
    return async (dispatch) => {
        dispatch({ type: GUEST_FORM_TOOGLE_LOADING });
        const response = await axios
            .delete(`${API.guests.delete}`, { data: Ids })
            .then((response) => {
                dispatch({ type: DELETE_GUEST, payload: Ids });
                dispatch({ type: GUEST_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Invitado eliminado.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: GUEST_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    };
};
