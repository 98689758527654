export const API = {
	musics: {
    create: `/music`,
    edit: `/music/`,
    delete: `/music/`,
    list: `/music`,
  },
	ideas: {
    create: `/idea`,
    edit: `/idea/`,
    delete: `/idea/`,
    list: `/idea`,
  },
	configurations: {
    create: `/configuration`,
    edit: `/configuration/`,
    delete: `/configuration/`,
    list: `/configuration`,
  },
	guests: {
    create: `/guest`,
    edit: `/guest/`,
    delete: `/guest/`,
    list: `/guest`,
  },
  auth: {
    login: `/user/login`,
    check2FA: `/user/check2FA`,
  },
  users: {
    profile: `/user/profile`,
    create: `/user/register/`,
    edit: `/user/`,
    delete: `/user/`,
    list: `/user`,
  },
  alerts: {
    create: `/alert`,
    edit: `/alert/`,
    delete: `/alert/`,
    list: `/alert`,
  },
};
