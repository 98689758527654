import { combineReducers } from "redux";
import {
  authorization,
  profile,
  currentRoute,
  notifications,
  app,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
  guests,
  guestsForm,
  configurations,
  configurationsForm,
  ideas,
  ideasForm,
  musics,
  musicsForm,
} from "./Reducers";

export default combineReducers({
  musics,
  musicsForm,
  ideas,
  ideasForm,
  configurations,
  configurationsForm,
  guests,
  guestsForm,
  app,
  authorization,
  currentRoute,
  profile,
  notifications,
  usersForm,
  users,
  alerts,
  alertsForm,
  currentTheme,
});
